// Commons
import '@commons/reset';
import '@commons/components';
import '@commons/matrix';
import '@commons/utils';

// Components
import '@styles/components/c-page-banner.scss';
import '@styles/components/c-tertiary-nav.scss';

import priorityNav from '@modules/priorityNav';

priorityNav();
